.iconBorder {
    -webkit-animation: animation4 infinite 9s;
    animation: animation4 infinite 9s;
}
.iconNoRotate {
    -webkit-animation: animation infinite 9s;
    animation: animation infinite 9s;
}

@keyframes animation4 {
    0%   {transform:  rotate(171deg)}
    50%   {transform:  rotate(50deg)}
    100%  {transform:  rotate(171deg)}
  }

  @keyframes animation {
    0%   {transform:  rotate(-171deg)}
    50%   {transform:  rotate(-50deg)}
    100%  {transform:  rotate(-171deg)}
  }